/*eslint-disable*/ export const messages = {
    '25': '25',
    '50': '50',
    '100': '100',
    '250': '250',
    '500': '500',
    "'expanded closed": "'erweitert geschlossen",
    "'expanded open": "'erweitert offen",
    '(all versions)': '(Alle Versionen)',
    '0 results found when searching for "{search}"': ['Keine Suchergebnisse für ', ['search'], ' gefunden'],
    '<0/> — <1>{title}</1>': ['<0/> — <1>', ['title'], '</1>'],
    '<0/>Prev': '<0/>Zurück',
    '<0>-- for sale</0> from --': '<0>-- Zum Verkauf</0> ab --',
    '<0><1/> Add Release</0>': '<0><1/> Veröffentlichung hinzufügen</0>',
    '<0>Add Release</0>': '<0>Veröffentlichung hinzufügen</0>',
    '<0>Click here</0> if you would like to view it in {from} instead of your default language ({to}).': [
        '<0>Klicken Sie hier</0>, wenn Sie es in ',
        ['from'],
        ' statt in Ihrer Standardsprache (',
        ['to'],
        ') anzeigen möchten.',
    ],
    '<0>Create Master Release</0>': '<0>Master-Release erstellen</0>',
    '<0>Credits</0>': '<0>Mitwirkende</0>',
    '<0>DO NOT USE</0> Please use <1>{0}</1> instead.': [
        '<0>BITTE NICHT VERWENDEN</0>Stattdessen bitte<1>',
        ['0'],
        '</1>verwenden.',
    ],
    '<0>Learn more about selling on Discogs<1/></0>': '<0>Erfahren Sie mehr über das Verkaufen auf Discogs<1/></0>',
    '<0>Showing 0 - 0 of 0</0>': '<0>0 - 0 von 0 angezeigt</0>',
    '<0>{0} for sale</0> from <1/>': ['<0>', ['0'], ' zum Verkauf</0> ab <1/>'],
    '<0>{0}</0>': ['<0>', ['0'], '</0>'],
    '<0>{creditName}</0>': ['<0>', ['creditName'], '</0>'],
    '<0>{name}</0> by <1>{username}</1>': ['<0>', ['name'], '</0> von <1>', ['username'], '</1>'],
    '<0>{submissionsPending} submissions pending</0>': ['<0>', ['submissionsPending'], ' Einträge ausstehend</0>'],
    API: 'API',
    ASIN: 'ASIN',
    'About Discogs': 'Über Discogs',
    'Accessibility Statement': 'Erklärung zur Barrierefreiheit',
    Actions: 'Aktionen',
    Ad: 'Anzeige',
    Add: 'Hinzufügen',
    'Add An Image': 'Ein Bild hinzufügen',
    'Add Artist to List': 'Künstler zur Liste hinzufügen',
    'Add Field': 'Add Field',
    'Add Folder': 'Add Folder',
    'Add List To Dashboard': 'Liste zum Dashboard hinzufügen',
    'Add Master to List': 'Master zur Liste hinzufügen',
    'Add Release': 'Veröffentlichung hinzufügen',
    'Add Release to List': 'Veröffentlichung zur Liste hinzufügen',
    'Add Review': 'Rezension hinzufügen',
    'Add Selected Release to Collection': 'Ausgewählte Veröffentlichungen zur Sammlung hinzufügen',
    'Add Selected Release to Inventory': 'Ausgewählte Veröffentlichung zum Inventar hinzufügen',
    'Add Selected Release to List': 'Ausgewählte Veröffentlichung zur Liste hinzufügen',
    'Add Selected Release to Wantlist': 'Ausgewählte Veröffentlichung zur Suchliste hinzufügen',
    'Add To Collection': 'Der Sammlung hinzufügen',
    'Add To Inventory': 'Zum Inventar hinzufügen',
    'Add To Wantlist': 'Zur Suchliste hinzufügen',
    'Add all versions to Wantlist': 'Alle Versionen zur Suchliste hinzufügen',
    'Add description...': 'Beschreibung hinzufügen...',
    'Add friend': 'Freund hinzufügen',
    'Add notes...': 'Anmerkungen hinzufügen...',
    'Add to Collection': 'Zur Sammlung hinzufügen',
    'Add to List': 'Zur Liste hinzufügen',
    'Add to Wantlist': 'Zur Suchliste hinzufügen',
    'Add to wantlist': 'Zur Suchliste hinzufügen',
    'Add video': 'Video hinzufügen',
    'Add {0} results to Wantlist': [['0'], ' Ergebnisse zur Suchliste hinzufügen'],
    Added: 'hinzugefügt',
    'Added folder': 'Ordner hinzugefügt',
    'Added just now': 'Gerade eben hinzugefügt',
    'Added video': 'Video hinzugefügt',
    'Advanced Search': 'Erweiterte Suche',
    'Advertise With Us': 'Schalten Sie Anzeigen bei uns',
    Advertisement: 'Werbung',
    Aliases: 'Aliasse',
    All: 'Alle',
    'All Items': 'Alle Artikel',
    'All Lists': 'Alle Listen',
    'All Media': 'Alle Medien',
    'All folders ({0})': ['All folders (', ['0'], ')'],
    'An error occurred when loading the videos.': 'Beim Laden der Videos ist ein Fehler aufgetreten.',
    Applications: 'Anwendungen',
    'Applying filters': 'Suchfilter anwenden ',
    'Are you sure you want to delete this field: "{0}"?': ['Möchten Sie dieses Feld wirklich löschen: "', ['0'], '"?'],
    'Are you sure you want to delete this folder: "{0}"?': [
        'Möchten Sie diesen Ordner wirklich löschen: "',
        ['0'],
        '"?',
    ],
    'Are you sure you want to remove these items?': 'Diese Artikel wirklich löschen?',
    'Are you sure you want to remove this item?': 'Diesen Artikel wirklich löschen?',
    Artist: 'Künstler',
    'Artist (A-Z)': 'Künstler (A-Z)',
    'Artist (Z-A)': 'Künstler (Z-A)',
    Artists: 'Künstler',
    'At least 10 words must be entered. Please enter at least {missingWords} more.': [
        'Es müssen mindestens 10 Wörter eingegeben werden. Bitte geben Sie noch mindestens ',
        ['missingWords'],
        ' mehr ein.',
    ],
    Audio: 'Audio',
    'Avg Rating': 'Durchschnittl. Bewertung',
    'Bad gateway.': 'Fehlerhaftes Gateway.',
    'Bad request.': 'Fehlerhafte Anfrage.',
    Barcode: 'Barcode',
    'Barcode and Other Identifiers': 'Barcode und andere Identifikationsmerkmale',
    'Based on last 30 sales': 'Basierend auf den letzten 30 Verkäufen',
    'Betacam-SP': 'Betacam-SP',
    Billing: 'Rechnungen',
    'Blu-ray': 'Blu-Ray',
    'Bottom pagination': 'Seitenzahlen unten',
    'Box Set': 'Box-Set',
    'Buy Music': 'Musik kaufen',
    Buyer: 'Käufer',
    By: 'Nach',
    CD: 'CD',
    CDr: 'CDr',
    'California Privacy Notice': 'Datenschutzerklärung Kalifornien',
    Cancel: 'Abbrechen',
    Careers: 'Karriere',
    Cart: 'Warenkorb',
    Cassette: 'Kassette',
    'Cat#': 'Kat.-Nr.',
    'Catalog Number': 'Katalognummer',
    'Catalog Number (0-9)': 'Katalognummer (0-9)',
    'Catalog Number (9-0)': 'Katalognummer (9-0)',
    Categories: 'Kategorien ',
    Choices: 'Auswahlmöglichkeiten',
    'Clear All': 'Alles löschen',
    'Clear Selection': 'Auswahl aufheben',
    'Clear all': 'Alles löschen',
    Close: 'Schließen',
    'Close Versions': 'Versionen schließen',
    'Collapse row': 'Reihe zuklappen',
    'Collapse section': 'Auswahl zusammenbrechen',
    Collected: 'Gesammelt',
    Collection: 'Sammlung',
    'Collection Items': 'Einträge der Sammlung',
    'Collection Settings': 'Sammlungs-Einstellungen',
    'Collection bottom pagination': 'Sammlung Seitenzahlen unten',
    Comments: 'Comments',
    'Comments on this item': 'Kommentare zu diesem Artikel',
    Community: 'Community',
    'Community Guidelines': 'Richtlinien für die Discogs-Community',
    'Companies, etc.': 'Unternehmen usw.',
    'Complete and correct': 'Vollständig und richtig',
    'Conflict.': 'Konflikt.',
    'Contact Info': 'Kontakt:',
    Contribute: 'Beitragen',
    Contributed: 'Eingetragen',
    'Contributor List': 'Rangliste der Eintragsersteller',
    Contributors: 'Beitragende',
    'Cookie Policy': 'Cookie-Bestimmungen',
    'Copy {0} Code': [['0'], ' Code kopieren'],
    'Copyright ©': 'Copyright ©',
    Correct: 'richtig',
    'Could not add Collection note field': 'Feld für Sammlungsanmerkung konnte nicht hinzugefügt werden',
    'Could not add folder': 'Ordner konnte nicht hinzugefügt werden',
    'Could not add release to collection': 'Veröffentlichung konnte nicht zur Sammlung hinzugefügt werden',
    'Could not add release to list': 'Veröffentlichung konnte nicht zur Liste hinzugefügt werden',
    'Could not add release to wantlist': 'Veröffentlichung konnte nicht zur Suchliste hinzugefügt werden',
    'Could not change your language preference': 'Spracheinstellung konnte nicht geändert werden',
    'Could not delete folder': 'Ordner konnte nicht gelöscht werden',
    'Could not edit release videos': 'Veröffentlichungsvideos konnten nicht bearbeitet werden',
    'Could not edit the collection note': 'Sammlungsanmerkung konnte nicht bearbeitet werden',
    'Could not edit the list': 'Die Liste konnte nicht bearbeitet werden',
    'Could not edit the list item': 'Das Listenelement konnte nicht bearbeitet werden',
    'Could not edit videos': 'Videos konnten nicht bearbeitet werden',
    'Could not edit wantlist note': 'Suchlistenanmerkung konnte nicht bearbeitet werden',
    'Could not mark review as helpful': 'Rezension konnte nicht als hilfreich markiert werden',
    'Could not move collection item': 'Sammlungsartikel konnte nicht verschoben werden',
    'Could not remove Collection note field': 'Feld für Sammlungsanmerkung konnte nicht entfernt werden',
    'Could not remove collection note': 'Sammlungsanmerkung konnte nicht entfernt werden',
    'Could not remove list': 'Liste konnte nicht entfernt werden',
    'Could not remove release from wantlist': 'Veröffentlichung konnte nicht aus Suchliste entfernt werden',
    'Could not remove review': 'Rezension konnte nicht entfernt werden',
    'Could not remove the list item': 'Das Listenelement konnte nicht entfernt werden',
    'Could not remove the release from your collection':
        'Die Veröffentlichung konnte nicht von Ihrer Sammlung entfernt werden',
    'Could not rename folder': 'Ordner konnte nicht umbenannt werden',
    'Could not save changes to Collection note field':
        'Änderungen am Feld für Sammlungsanmerkung konnte nicht gespeichert werden',
    'Could not update dashboard': 'Dashboard konnte nicht aktualisiert werden',
    Count: 'Zählen',
    Countries: 'Länder',
    Country: 'Land',
    'Cover of {0}, {1}, {2}': ['Cover von ', ['0'], ', ', ['1'], ', ', ['2']],
    'Cover of {title}': ['Cover von ', ['title']],
    Created: 'Erstellt',
    Credits: 'Mitwirkende (Credits)',
    'Credits ({0})': ['Mitwirkende (', ['0'], ')'],
    'Current Videos': 'Aktuelle Videos',
    DVD: 'DVD',
    Dashboard: 'Übersicht',
    'Data Correct': 'Daten sind korrekt',
    'Data Quality': 'Datenqualität',
    'Data quality rating:': 'Bewertung der Datenqualität:',
    'Database Guidelines': 'Datenbank-Richtlinien',
    'Date added': 'Hinzugefügt am',
    Delete: 'Löschen',
    'Delete Field': 'Feld löschen',
    'Delete List': 'Liste löschen',
    'Delete Review': 'Rezension löschen',
    'Delete This List': 'Diese Liste löschen',
    'Delete forever': 'Delete forever',
    'Depósito Legal': 'Depósito Legal',
    Description: 'Beschreibung',
    'Designed At': 'Design von',
    Developers: 'Entwickler',
    'Did you mean the {0} named {1}': ['Meinten Sie ', ['0'], ' namens ', ['1'], '?'],
    'Digital content is not available for sale or trade.':
        'Digitale Inhalte können nicht verkauft oder getauscht werden.',
    Disco: 'Disco',
    'Discogs Shipping': 'Versenden mit Discogs',
    'Discogs is not responsible for the accuracy, legality, or content of external sites or for that of subsequent links.':
        'Discogs is not responsible for the accuracy, legality, or content of external sites or for that of subsequent links.',
    'Discogs offensive marketplace item policies': 'Discogs offensive marketplace item policies',
    Discover: 'Entdecken',
    'Distributed By': 'Vertrieb durch',
    'Distribution Code': 'Verteilungscode',
    'Do you really want to remove this list?': 'Möchten Sie diese Liste wirklich entfernen?',
    'Do you really want to remove this review?': 'Diese Rezension wirklich entfernen?',
    'Download now from Google Play': 'Jetzt bei Google Play herunterladen',
    'Download now from the App Store': 'Jetzt im App-Store herunterladen',
    Draft: 'Entwurf',
    Drafts: 'Entwürfe',
    Dropdown: 'Dropdown',
    'Duplicated By': 'Vervielfältigung durch',
    Edit: 'Bearbeiten',
    'Edit Artist': 'Künstler bearbeiten',
    'Edit Images': 'Bilder bearbeiten',
    'Edit Label': 'Label bearbeiten',
    'Edit Master Release': 'Master-Release bearbeiten',
    'Edit Notes': 'Notizen bearbeiten',
    'Edit Release': 'Veröffentlichung bearbeiten',
    'Edit collection note': 'Edit collection note',
    'Edited At': 'Bearbeitet von',
    'Edited just now': 'Gerade eben bearbeitet',
    'Editing Videos for <0>{name}</0>': ['Videos bearbeiten für <0>', ['name'], '</0>'],
    Electronic: 'Electronic (Elektronische Musik)',
    'Enable Keyboard Shortcuts:': 'Tastaturkürzel aktivieren',
    'Engineered At': 'Realisiert von',
    English: 'Englisch',
    'Enter your comment': 'Kommentar eingeben',
    'Enter your reply': 'Geben Sie Ihre Antwort ein',
    'Entirely Incorrect': 'Vollständig falsch',
    Error: 'Fehler',
    'Error fetching random item': 'Fehler beim Abrufen eines zufälligen Eintrags',
    'Estimated Collection Value': 'Estimated Collection Value',
    Everything: 'Alles',
    'Exclusive Retailer': 'Exklusiver Einzelhändler',
    'Existing List': 'Existierende Liste',
    'Expand row': 'Reihe erweitern',
    'Expand section': 'Auswahl erweitern',
    'Expectation failed.': 'Erwartung fehlgeschlagen.',
    Experimental: 'Experimentelle Musik',
    Explore: 'Stöbern',
    'Explore All': 'Stöbern',
    'Explore lists from the Discogs community. Lists can be about anything–notable album covers, prolific producers, your favorite holiday albums. The possibilities are endless! Lists can contain artists, releases, labels, or even other lists.':
        'Stöbern Sie in Listen der Discogs Gemeinschaft. Listen können zu allen Themen erstellt werden - bemerkenswerte Albumcover, erfolgreichen Produzenten oder Alben, die Sie an den Urlaub erinnern - die Möglichkeiten sind endlos! Listen können Künstler, Veröffentlichungen, Label oder sogar andere Listen enthalten.',
    "Explore music from the {name} label. Discover what's missing in your collection and shop for {name} releases.": [
        'Entdecken Sie Musik des Labels ',
        ['name'],
        '. Finden Sie heraus, was in Ihrer Sammlung fehlt, und kaufen Sie ',
        ['name'],
        '-Veröffentlichungen.',
    ],
    'Explore songs, recommendations, and other album details for {title} by {0}. Compare different versions and buy them all on Discogs.':
        [
            'Entdecken Sie Songs, Empfehlungen und andere Albumdetails für ',
            ['title'],
            ' von ',
            ['0'],
            '. Vergleichen Sie unterschiedliche Versionen und kaufen Sie alle auf Discogs. ',
        ],
    'Explore the discography of {name}. Shop for vinyl, CDs, and more from {name} on Discogs.': [
        'Durchsuche die Diskografie von ',
        ['name'],
        '. Kaufe Vinyl, CDs und mehr von ',
        ['name'],
        'auf Discogs.',
    ],
    'Explore the tracklist, credits, statistics, and more for {title} by {0}. Compare versions and buy on Discogs': [
        'Durchsuchen Sie die Trackliste, Mitwirkenden, Statistiken und mehr nach ',
        ['title'],
        ' von ',
        ['0'],
        '. Vergleichen Sie Versionen und kaufen Sie auf Discogs.',
    ],
    "Explore {name}'s biography, discography, and artist credits. Shop rare vinyl records, top albums, and more on Discogs.":
        [
            'Explore ',
            ['name'],
            "'s biography, discography, and artist credits. Shop rare vinyl records, top albums, and more on Discogs.",
        ],
    Export: 'Datenexport',
    'Export Collection': 'Sammlung exportieren',
    'Exported By': 'Exportiert durch',
    Facebook: 'Facebook',
    'Failed dependency.': 'Abhängigkeit fehlgeschlagen.',
    'Field Name': 'Feldname',
    File: 'Datei',
    'Filmed At': 'Gefilmt von',
    'Filter by': 'Filtern nach',
    Filters: 'Filter',
    'Find a country': 'Land suchen',
    'Find a format': 'Format suchen',
    'Find a label or company': 'Finden Sie ein Label oder Unternehmen',
    'Find a year': 'Jahr suchen',
    'Focus on Search': 'Schnellsuche',
    Folder: 'Ordner',
    'Folder Name': 'Folder Name',
    'Folder deleted': 'Ordner gelöscht',
    'Folder renamed': 'Ordner umbenannt',
    'Folk, World, & Country': 'Folk, Weltmusik & Country',
    'Follow Us': 'Folgen Sie uns',
    Footer: 'Fußzeile',
    'For Sale': 'Zu Verkaufen',
    'For sale on Discogs': 'Zum Verkauf bei Discogs',
    'Forbidden.': 'Verboten.',
    Format: 'Format',
    'Format (A-Z)': 'Format (A-Z)',
    'Format (Z-A)': 'Format (Z-A)',
    Formats: 'Formate',
    Forum: 'Forum',
    French: 'Französisch',
    Friends: 'Freunde',
    From: 'Von',
    'Funk / Soul': 'Funk / Soul',
    'Gateway timeout.': 'Gateway-Zeitüberschreitung.',
    Genre: 'Genre',
    Genres: 'Genres',
    German: 'Deutsch',
    'Get Started': 'So legen Sie los',
    'Glass Mastered At': 'Glasmastering bei',
    'Go To Collection': 'Zur Sammlung',
    'Go To Dashboard': 'Zum Dashboard',
    'Go To Edit': 'Zur Bearbeitung',
    'Go To Forum': 'Zum Forum',
    'Go To Groups': 'Zu den Gruppen',
    'Go To Inventory': 'Zum Inventar',
    'Go To Messages': 'Zu den Nachrichten',
    'Go To Orders': 'Zu den Bestellungen',
    'Go To Posted': 'Zu Ihren Postings',
    'Go To Purchases': 'Zu den Käufen',
    'Go To Recent': 'Aktuelle Beiträge',
    'Go To Saved': 'Gespeicherte Threads',
    'Go To Started': 'Ihre Threads',
    'Go To Submissions': 'Zu den Einträgen',
    'Go To The Selected Release': 'Zur ausgewählten Veröffentlichung',
    'Go To User Profile': 'Zum Benutzerprofil',
    'Go To Wantlist': 'Zur Suchliste',
    'Go To Watched': 'Beobachtete Threads',
    'Go to Discogs.com homepage': 'Zur Discogs.com-Homepage wechseln',
    'Gone.': 'Nicht mehr verfügbar.',
    Groups: 'Gruppen',
    'HTTP version not supported.': 'HTTP-Version nicht unterstützt.',
    Have: 'Haben',
    'Have a unique version? <0>Add a Release</0>':
        'Sie haben eine einzigartige Version? <0>Veröffentlichung hinzufügen</0>',
    Help: 'Hilfe',
    'Help & Support': 'Hilfe und Support',
    'Help Is Here': 'Hilfe gibt es hier',
    'Help Translate': 'Bei der Übersetzung mithelfen',
    'Help Translate Discogs': 'Helfen, Discogs zu übersetzen',
    'Help!': 'Hilfe!',
    Helpful: 'Hilfreich',
    'Here are a few lists we think deserve a mention.':
        'Hier sind einige Listen, die für uns eine Erwähnung verdienen.',
    'Hide Ad': 'Anzeigen ausblenden',
    'Hide Credits': 'Mitwirkende ausblenden',
    'Hide notes': 'Hide notes',
    'Hide replies': 'Antworten ausblenden',
    High: 'Hoch',
    'High Price (0-9)': 'Hochpreisig (0-9)',
    'High Price (9-0)': 'Hochpreisig (9-0)',
    Highest: 'Höchster',
    'Hip Hop': 'Hip Hop',
    House: 'House',
    'Hover on an item\'s comments and click to edit, or click "remove" to remove.':
        'Bewegen Sie den Mauszeiger über die Artikelkommentare und klicken Sie, um diese zu bearbeiten. Um sie zu entfernen, klicken sie auf "entfernen".',
    'Hover on the title or description then click to edit.':
        'Fahren Sie mit dem Mauszeiger über den Titel oder die Beschreibung und klicken Sie, um diesen/diese zu bearbeiten.',
    'How to Make a List': 'Wie Sie eine Liste anlegen',
    "I'm a teapot.": 'Ich bin eine Teekanne.',
    ISRC: 'ISRC',
    'If this release contains spam, help us fight it by reporting the release as spam.':
        'If this release contains spam, help us fight it by reporting the release as spam.',
    'If you wish to report other illegal activity, you are welcome to':
        'If you wish to report other illegal activity, you are welcome to',
    Image: 'Bild',
    'Image Gallery': 'Bildergalerie',
    'Image hidden': 'Bild verborgen',
    'Image hidden due to content': 'Bild aufgrund des Inhalts verborgen',
    Images: 'Bilder',
    Impressum: 'Impressum',
    'In Collection': 'In Sammlung',
    'In Groups': 'Gruppen',
    'In Wantlist': 'In Suchliste',
    'In Your Collection, Wantlist, or Inventory': 'In Ihrer Sammlung, Suchliste oder Ihrem Inventar',
    'Insufficient space on resource.': 'Speicherplatz auf der Ressource unzureichend.',
    'Insufficient storage.': 'Speicherplatz unzureichend.',
    Inventory: 'Inventar',
    Italian: 'Italienisch',
    'Items I Want': 'Artikel, die ich suche',
    Japanese: 'Japanisch',
    Jazz: 'Jazz',
    'Join In': 'Mitmachen',
    'Keyboard Shortcuts': 'Tastaturkürzel',
    Korean: 'Koreanisch',
    Label: 'Label',
    'Label (A-Z)': 'Label (A-Z)',
    'Label (Z-A)': 'Label (Z-A)',
    'Label Code': 'Labelcode',
    'Label releases bottom pagination': 'Label-Veröffentlichungen Seitenzahlen unten',
    'Label releases top pagination': 'Label-Veröffentlichungen Seitenzahlen oben',
    Labels: 'Labels',
    'Labels & Companies': 'Labels und Unternehmen',
    'Labels or Companies': 'Labels oder Firmen',
    Labs: 'Labs',
    'Lacquer Cut At': 'Acetat-Lack-Schnitt von',
    'Last Sold': 'Zuletzt verkauft',
    'Learn more about blocked items on Discogs': 'Erfahren Sie mehr über blockierte Elemente auf Discogs',
    'Learn more about selling on Discogs': 'Erfahren Sie mehr über das Verkaufen auf Discogs',
    'Learn why': 'Erfahren Sie den Grund',
    Legal: 'Rechtliches',
    'Length required.': 'Länge erforderlich.',
    'Licensed From': 'Lizenziert durch',
    'Licensed Through': 'Lizenziert über',
    'Licensed To': 'Lizenziert für',
    Lines: 'Zeilen',
    Links: 'Links',
    List: 'Liste',
    'List Explorer': 'In Listen stöbern',
    'List Items For Sale': 'Artikel zum Verkauf einstellen',
    Lists: 'Listen',
    'Lists We Like': 'Listen, die uns gefallen',
    'Lists by {username}': ['Listen von ', ['username']],
    'Lists by {username} | Discogs': ['Listen von ', ['username'], ' | Discogs'],
    'Load More Reviews': 'Weitere Rezensionen laden',
    'Loading releases': 'Veröffentlichungen werden geladen',
    'Loading versions': 'Versionen werden geladen',
    'Locked.': 'Gesperrt.',
    'Log In': 'Anmelden',
    'Log Out': 'Abmelden',
    'Log in to view image': 'Zum Anzeigen des Bilds anmelden',
    'Logged in as {username}': ['Angemeldet als ', ['username']],
    Low: 'Niedrig',
    'Low Price (0-9)': 'Günstig (0-9)',
    'Low Price (9-0)': 'Günstig (9-0)',
    Lowest: 'Niedrigster',
    'Made By': 'Produziert von',
    Main: 'Hauptseite',
    'Main Menu': 'Hauptmenü',
    'Manage Collection Fields': 'Manage Collection Fields',
    'Manage Collection Folders': 'Manage Collection Folders',
    'Manage Collection Folders And Custom Fields': 'Manage Collection Folders And Custom Fields',
    'Manage Custom Fields': 'Manage Custom Fields',
    'Manage Custom Folders': 'Manage Custom Folders',
    'Manage List': 'Liste verwalten',
    'Manage My Lists': 'Meine Listen bearbeiten',
    'Manage Preferences': 'Einstellungen verwalten',
    'Manage videos': 'Videos verwalten',
    'Manufactured By': 'Hergestellt durch',
    'Manufactured For': 'Hergestellt für',
    'Manufacturer Contact': 'Manufacturer Contact',
    'Manufacturer EU Contact': 'Manufacturer EU Contact',
    'Marketed By': 'Marketing durch',
    Marketplace: 'Marktplatz',
    'Master Release': 'Master-Release',
    'Mastered At': 'Mastering bei',
    'Mastering SID Code': 'Mastering-SID-Code',
    'Matrix / Runout': 'Matrix / Runout',
    Maximum: 'Maximum',
    Med: 'Mittelwert.',
    Media: 'Tonträger',
    'Media condition': 'Zustand des Tonträgers',
    Median: 'Durchschnitt',
    'Median Price (0-9)': 'Mittelpreisig (0-9)',
    'Median Price (9-0)': 'Mittelpreisig (9-0)',
    Members: 'Mitglieder',
    'Method failure.': 'Methode fehlgeschlagen.',
    'Method not allowed.': 'Methode nicht zulässig.',
    Minimum: 'Minimum',
    'Misdirected request.': 'Fehlgeleitete Anfrage.',
    'Mixed At': 'Abgemischt bei',
    'Monthly Leaderboards': 'Monatliche Bestenlisten',
    'More images': 'Mehr Bilder',
    'More results...': 'Weitere Ergebnisse …',
    'Mould SID Code': 'Mould-SID-Code',
    'Move Selection Down': 'Auswahl nach unten verschieben',
    'Move Selection Up': 'Auswahl nach oben verschieben',
    'Move item to:': 'Eintrag verschieben nach:',
    'Move items to:': 'Einträge verschieben nach:',
    'Move to Folder...': 'Move to Folder...',
    'Move to position...': 'An Position verschieben...',
    'Moved to folder': 'In Ordner verschoben',
    'My Store': 'Mein Shop',
    'My Wantlist | Discogs': 'Meine Suchliste | Discogs',
    'Navigate to {title}': ['Zu ', ['title'], ' navigieren'],
    Navigation: 'Navigation',
    'Needs Changes': 'Benötigt Veränderungen',
    'Needs Vote': 'Muss noch bewertet werden',
    'Needs major changes': 'Benötigt wichtige Bearbeitungen',
    'Needs minor changes': 'Benötigt kleinere Bearbeitungen',
    Never: 'Nie',
    'New List': 'Neue Liste',
    'New Submission': 'Neuer Eintrag',
    'Newest Addition': 'Neueste Einträge',
    Next: 'Weiter',
    'Next image': 'Nächstes Bild',
    'Next<0/>': 'Nächster<0/>',
    'No image available': 'Kein Bild verfügbar',
    'No image available; add an image': 'Kein Bild verfügbar, fügen Sie ein Bild hinzu',
    'No items available in the Marketplace': 'Keine Einträge auf dem Marktplatz verfügbar',
    'No items found.': 'Keine Artikel gefunden.',
    'No items found. Try different keywords or check your spelling.':
        'No items found. Try different keywords or check your spelling.',
    'No versions could be found using these filters.': 'Mit diesen Filtern konnten keine Versionen gefunden werden.',
    'Not acceptable.': 'Nicht akzeptabel.',
    'Not implemented.': 'Nicht implementiert.',
    Notes: 'Anmerkungen',
    'Nothing in collection.': 'Nichts in Sammlung.',
    Notifications: 'Benachrichtigungen',
    'Notify me of new submissions related to this list.':
        'Benachrichtigen Sie mich, wenn es neue Einträge in dieser Liste gibt.',
    OK: 'Okay',
    Okay: 'OK',
    'Oldest Addition': 'Ältester Eintrag',
    'On The Go': 'Auf dem Weg',
    'Only items in violation of': 'Only items in violation of',
    'Oops! Could not file report, please submit a support ticket.':
        'Oops! Could not file report, please submit a support ticket.',
    Optional: 'Optional',
    Options: 'Optionen',
    Orders: 'Bestellungen',
    Other: 'Andere',
    'Other Versions': 'Andere Versionen',
    'Other Versions ({0} of {total})': ['Andere Versionen (', ['0'], ' von ', ['total'], ')'],
    'Other Versions ({0})': ['Andere Versionen (', ['0'], ')'],
    'Overdubbed At': 'Overdub von',
    'Parent Label': 'Mutterlabel',
    Pause: 'Pause',
    'Payment required.': 'Zahlung erforderlich.',
    Permalink: 'Permalink',
    'Phonographic Copyright ℗': 'Phonographisches Copyright ℗',
    Play: 'Wiedergabe',
    'Play {title}': ['abspielen ', ['title']],
    Pop: 'Pop',
    'Pop Rock': 'Pop-Rock',
    Portuguese: 'Portugiesisch',
    Position: 'Position',
    'Precondition failed.': 'Voraussetzung fehlgeschlagen.',
    'Precondition required.': 'Voraussetzung erforderlich.',
    'Pressed By': 'Gepresst durch',
    'Pressing Plant ID': 'Presswerk-ID',
    Prev: 'Zurück',
    Preview: 'Vorschau',
    Previous: 'Vorherige',
    'Previous image': 'Vorheriges Bild',
    'Price Code': 'Preiscode',
    'Printed By': 'Druck durch',
    Privacy: 'Privatsphäre',
    'Privacy Policy': 'Datenschutz',
    'Produced At': 'Produziert von',
    'Produced For': 'Produziert für',
    Profile: 'Profil',
    'Prog Rock': 'Progressive Rock',
    'Proxy authentication required.': 'Proxy-Authentifizierung erforderlich.',
    Public: 'Öffentlich',
    'Published By': 'Veröffentlicht durch',
    Punk: 'Punk',
    Purchases: 'Einkäufe',
    'Quote Previous': 'Vorheriges zitieren',
    'Random Item': 'Random Item',
    Rating: 'Bewertung',
    'Rating (0-9)': 'Bewertung (0-9)',
    'Rating (9-0)': 'Bewertung (9-0)',
    Ratings: 'Bewertungen',
    'Read More': 'Lesen Sie mehr',
    'Read More About Creating Lists': 'Erfahren Sie mehr darüber, wie man Listen anlegt',
    'Real Name': 'Bürgerl. Name',
    'Recent Lists': 'Aktuelle Listen',
    'Recently Created Lists | Discogs Lists': 'Neueste Listen | Discogs-Listen',
    'Recently Edited': 'Vor kurzem bearbeitet',
    'Recently Used': 'Vor kurzem verwendet',
    Recommendations: 'Empfehlungen',
    'Record Company': 'Plattenfirma',
    'Record Stores': 'Plattenläden',
    'Recorded At': 'Aufgenommen bei',
    'Recorded By': 'Aufgenommen von',
    'Reel-To-Reel': 'Reel-To-Reel (Tonband)',
    Register: 'Registrieren',
    'Related Videos': 'Passende Videos',
    Release: 'Veröffentlichung',
    Released: 'Veröffentlicht',
    Releases: 'Veröffentlichungen (Releases)',
    'Remastered At': 'Remastering bei',
    'Remixed At': 'Remix von',
    Remove: 'Entfernen',
    'Remove Item': 'Artikel entfernen',
    'Remove Items': 'Artikel entfernen',
    'Remove Rating': 'Bewertung entfernen',
    'Remove Selected Release from Wantlist': 'Ausgewählte Veröffentlichung aus Suchliste entfernen',
    'Remove all versions from Wantlist': 'Alle Versionen von der Suchliste entfernen',
    'Remove from Collection': 'Aus der Sammlung entfernen',
    'Remove from Wantlist': 'Von Suchliste entfernen',
    Removed: 'Entfernt',
    'Removed Videos': 'Videos entfernt',
    'Removed video': 'Entferntes Video',
    Rename: 'Umbenennen',
    Reply: 'Antworten',
    Report: 'Melden',
    'Report Offensive Material': 'Report Offensive Material',
    'Report Release': 'Report Release',
    'Report Spam': 'Report Spam',
    'Report Suspicious Activity': 'Verdächtige Aktivitäten melden',
    'Request entity too large.': 'Anfrageeinheit zu groß',
    'Request header fields too large.': 'Anfrage-Header-Felder zu groß.',
    'Request timeout.': 'Anfragezeitüberschreitung.',
    'Request-URI too long.': 'Anfrage-URI zu lang.',
    'Requested range not satisfiable.': 'Angefragter Bereich nicht erfüllbar.',
    'Revert to the old version.': 'Alte Version wiederherstellen',
    Reviews: 'Rezensionen',
    'Rights Society': 'Rechtegesellschaft',
    Rock: 'Rock',
    Russian: 'Russisch',
    'SPARS Code': 'SPARS-Code',
    Save: 'Speichern',
    'Save Changes': 'Änderungen speichern',
    'Save Fields': 'Save Fields',
    'Saved your changes': 'Ihre Änderungen wurden gespeichert',
    'Saved your changes.': 'Ihre Änderungen wurden gespeichert.',
    'Saving...': 'Wird gespeichert...',
    'Scroll left': 'Nach links scrollen',
    'Scroll right': 'Nach rechts scrollen',
    Search: 'Suchen',
    'Search & Filters': 'Suche und Filter',
    'Search Collection': 'Sammlung durchsuchen',
    'Search Discography': 'Diskografie durchsuchen',
    'Search Discogs': 'Discogs durchsuchen',
    'Search Lists': 'Listen durchsuchen',
    'Search artists, albums and more...': 'Suche nach Künstlern, Alben und mehr...',
    'Search barcodes and other identifiers...': 'Barcode und andere Identifikationsmerkmale suchen …',
    'Search suggestions': 'Suchvorschlag',
    'Search...': 'Suchen …',
    'See all versions': 'Alle Versionen ansehen',
    'Select an item': 'Einen Eintrag auswählen',
    'Select {0} with {1} {2}': ['auswählen ', ['0'], ' mit ', ['1'], ' ', ['2']],
    'Sell All Media': 'Alle Medien verkaufen',
    'Sell Betacam-SP': 'Betacam-SP verkaufen',
    'Sell Blu-ray': 'Blu-Ray verkaufen',
    'Sell Box Set': 'Box-Set verkaufen',
    'Sell CD': 'CD verkaufen',
    'Sell CDr': 'CDr verkaufen',
    'Sell Cassette': 'Kassette verkaufen',
    'Sell DVD': 'DVD verkaufen',
    'Sell File': 'Datei verkaufen',
    'Sell Music': 'Musik verkaufen',
    'Sell Reel-To-Reel': 'Tonband verkaufen',
    'Sell U-Matic': 'U-Matic verkaufen',
    'Sell VHS': 'VHS verkaufen',
    'Sell Vinyl': 'Vinyl verkaufen',
    'Sell a Copy': 'Exemplar verkaufen',
    'Sell a copy': 'Ein Exemplar verkaufen',
    Seller: 'Verkäufer',
    'Send a message': 'Eine Nachricht senden',
    Series: 'Serie',
    'Service unavailable.': 'Dienst nicht verfügbar.',
    Settings: 'Einstellungen',
    Share: 'Teilen',
    Shop: 'Shop',
    'Shop now': 'Jetzt stöbern',
    'Shop this version': 'Diese Version kaufen',
    Show: 'Anzeigen',
    'Show Credits': 'Mitwirkende anzeigen',
    'Show Shortcut Help': 'Hilfe für Kürzel anzeigen',
    'Show all {0} tracks': ['Alle ', ['0'], ' Track anzeigen'],
    'Show all {totalCount} versions': ['Alle ', ['totalCount'], 'Versionen anzeigen'],
    'Show images of {0}, {1}, {2}': ['Bilder von ', ['0'], ', ', ['1'], ' und ', ['2'], ' anzeigen'],
    'Show less': 'Weniger anzeigen',
    'Show more': 'Mehr anzeigen',
    'Show more credits...': 'Mehr Mitwirkende anzeigen …',
    'Show notes': 'Show notes',
    'Showing {0}-{1} of {2}': [['0'], '-', ['1'], ' von ', ['2'], ' werden angezeigt'],
    'Showing {0}-{adjustedCount} of {adjustedTotalCount}': [
        ['0'],
        '-',
        ['adjustedCount'],
        ' von ',
        ['adjustedTotalCount'],
        ' werden angezeigt',
    ],
    Sites: 'Seiten',
    'Sleeve condition': 'Zustand des Covers',
    'Something went wrong': 'Es ist ein Fehler aufgetreten.',
    'Something went wrong adding your review': 'Beim Hinzufügen Ihrer Rezension ist ein Fehler aufgetreten.',
    'Something went wrong in this section of the page.': 'In diesem Bereich der Seite ist ein Fehler aufgetreten.',
    'Something went wrong in this section.': 'In diesem Bereich ist ein Fehler aufgetreten.',
    'Something went wrong please try again': 'Something went wrong please try again',
    'Something went wrong.': 'Irgendetwas hat nicht funktioniert.',
    'Sorry, this Artist does not have any Releases under that name.':
        'Es tut uns leid, aber dieser Künstler hat unter diesem Namen nichts veröffentlicht.',
    'Sorry, this Label does not have any Releases under that name.':
        'Es tut uns leid, aber dieses Label hat unter diesem Namen nichts veröffentlicht.',
    Sort: 'Sortieren',
    Soul: 'Soul',
    Spanish: 'Spanisch',
    'Start Selling': 'Verkauf starten',
    Statistics: 'Statistiken',
    Status: 'Status',
    'Stop impersonating': 'Hören Sie auf, sich als jemand auszugeben',
    'Stop impersonating {0}': ['Hören Sie auf, sich als ', ['0'], ' auszugeben'],
    Style: 'Stil',
    Styles: 'Stile',
    Sublabels: 'Sublabel',
    'Submission Guidelines': 'Beitragsrichtlinien',
    Submissions: 'Einträge',
    Submit: 'Abschicken',
    'Submit Spam Report': 'Submit Spam Report',
    'Submit a Release': 'Eine Veröffentlichung eintragen',
    'Switch to text-only view': 'Zur Nur-Text-Ansicht wechseln',
    'Synth-Pop': 'Synth-Pop',
    'Terms of Service': 'Nutzungsbedingungen',
    'Text area': 'Text area',
    'Text-only view': 'Textansicht',
    'Thanks, your report was received.': 'Thanks, your report was received.',
    'That artist does not exist or may have been deleted.':
        'Dieser Künstler existiert nicht oder wurde möglicherweise gelöscht.',
    'That label does not exist or may have been deleted.':
        'Dieses Label existiert nicht oder wurde möglicherweise gelöscht.',
    'That list does not exist or may have been deleted.':
        'Diese Liste existiert nicht oder wurde möglicherweise gelöscht.',
    'That release does not exist or may have been deleted.':
        'Diese Veröffentlichung existiert nicht oder wurde möglicherweise gelöscht.',
    "The Artist {0} is invalid. Please refer to this Artist instead. View <0>{1}'s edit history</0>.": [
        'Der Künstler ',
        ['0'],
        ' ist ungültig. Bitte verwenden Sie stattdessen diesen Künstler. <0>Verlauf von ',
        ['1'],
        '</0> anzeigen.',
    ],
    "The Label {0} is invalid. Please refer to this Label instead. View <0>{1}'s edit history</0>.": [
        'Das Label ',
        ['0'],
        ' ist ungültig. Bitte verwenden Sie stattdessen dieses Label. <0>Verlauf von ',
        ['1'],
        '</0> anzeigen.',
    ],
    'The value "{id}" is not a valid Discogs identifier.': [
        'Der Wert „',
        ['id'],
        '“ ist kein gültiges Discogs-Identifizierungsmerkmal.',
    ],
    'There are no videos here': 'Hier gibt es keine Videos.',
    'This artist does not exist or may have been deleted.':
        'Dieser Künstler existiert nicht oder wurde möglicherweise gelöscht.',
    'This artist is used as a placeholder entry and does not link to any artist.':
        'Dieser Eintrag ist ein Platzhalter und verweist auf keinen Künstler.',
    'This master release does not exist or may have been deleted.':
        'Diese Master-Veröffentlichung existiert nicht oder wurde möglicherweise gelöscht.',
    'This release is blocked from sale on Discogs. It is not permitted to sell this item in our Marketplace.':
        'Diese Veröffentlichung wurde für den Verkauf auf Discogs gesperrt. Es ist nicht erlaubt, diesen Artikel auf unserem Marktplatz zu verkaufen.',
    'Thumbnail image': 'Vorschaubild',
    Title: 'Titel',
    'Title (A-Z)': 'Titel (A-Z)',
    'Title (Z-A)': 'Titel (Z-A)',
    'Toggle Community Menu': 'Zum Community-Menü wechseln',
    'Toggle Explore Menu': 'Menü Stöbern umschalten',
    'Toggle Marketplace Menu': 'Marktplatz-Menü umschalten',
    'Toggle Zoom': 'Zoom umschalten',
    'Toggle section': 'Abschnitt umschalten',
    'Too many requests.': 'Zu viele Anfragen.',
    'Top pagination': 'Seitenzahlen oben',
    Tracklist: 'Trackliste',
    'Tracks {0} — {1} of {2}': ['Tracks ', ['0'], ' — ', ['1'], ' von ', ['2']],
    Twitter: 'Twitter',
    Type: 'Art',
    'U-Matic': 'U-Matic',
    'Unauthorized.': 'Nicht autorisiert.',
    'Unavailable due to legal reasons.': 'Aus rechtlichen Gründen nicht verfügbar.',
    'Undo changes': 'Änderungen rückgängig machen',
    Unknown: 'Unbekannt',
    'Unprocessable entity.': 'Einheit kann nicht verarbeitet werden.',
    'Unsupported media type.': 'Medientyp nicht unterstützt.',
    'Use drag and drop to re-order the items in your list, or click "move to," type a new position, and press enter.':
        'Ordnen Sie Einträge via Drag-and-Drop neu an oder klicken Sie auf "Verschieben", geben Sie eine neue Position ein und drücken Sie die Eingabe-Taste.',
    User: 'Nutzer',
    'User Menu': 'Benutzermenü',
    VHS: 'VHS',
    Variations: 'Varianten',
    'Version Details': 'Versionsdaten ',
    Versions: 'Versionen',
    'Video Player': 'Video-Player',
    'Video Search': 'Videosuche',
    Videos: 'Videos',
    'Videos from related releases, artists, or labels.':
        'Videos von ähnlichen Veröffentlichungen, Künstlern oder Labels.',
    'View All': 'Alle anzeigen',
    'View Help': 'Hilfe anzeigen',
    'View Images for Selected Release': 'Videobilder für ausgewählte Veröffentlichung',
    'View More {name}': ['Mehr von ', ['name'], ' anzeigen'],
    'View Submissions': 'Einträge anzeigen',
    'View all of my lists': 'Alle meine Listen anzeigen',
    'View credits, reviews, tracks and shop for the {info} release of "{title}" on Discogs.': [
        'Auf Discogs können Sie sich ansehen, wer an ',
        ['info'],
        'von ',
        ['title'],
        ' mitgewirkt hat, Rezensionen und Titellisten lesen und auf dem Marktplatz nach der Veröffentlichung suchen.',
    ],
    'View in admin': 'Als Administrator ansehen',
    Viewing: 'Wird angezeigt',
    'Viewing All': 'Alle werden angezeigt',
    Vinyl: 'Vinyl',
    'Visit Wantlister': 'Wantlister besuchen',
    Want: 'Suchen',
    'Want to see other lists from the Discogs Community? Check out <0>Recent Lists.</0>':
        'Wollen Sie andere Listen der Discogs-Community sehen? Werfen Sie einen Blick auf die <0>Neuesten Listen</0>.',
    'Want to see other lists from the Discogs Community? Check out <0>Recent Lists</0>.':
        'Wollen Sie andere Listen der Discogs-Community sehen? Werfen Sie einen Blick auf die <0>Neuesten Listen</0>.',
    Wanted: 'Gesucht',
    Wantlist: 'Suchliste',
    'We could not find that page.': 'Wir konnten diese Seite nicht finden.',
    Year: 'Jahr',
    'Year (0-9)': 'Jahr (0-9)',
    'Year (9-0)': 'Jahr (9-0)',
    'You are currently viewing the new version of the release page.':
        'Sie sehen derzeit die neue Version der Release-Seite',
    'You are impersonating {0}.': ['Sie geben sich als ', ['0'], ' aus.'],
    'You are viewing the new version of the Master Page.': 'Sie sehen derzeit die neue Version der Master-Seite.',
    'You can update your default language preference in your <0>General Settings</0>.':
        'Sie können Ihre Standard-Spracheinstellung in Ihren <0>allgemeinen Einstellungen</0> aktualisieren.',
    'You clicked on the {from} version of this page.': [
        'Sie haben auf die ',
        ['from'],
        ' Version dieser Seite geklickt.',
    ],
    'You do not have any custom note fields for your collection. Create some by clicking Add a field':
        'Sie haben keine benutzerdefinierten Anmerkungsfelder für Ihre Sammlung. Erstellen Sie welche, indem Sie auf Feld hinzufügen klicken',
    'You need to be logged in to see this page.': 'Sie müssen angemeldet sein, um diese Seite zu sehen.',
    'YouTube search query:': 'YouTube-Suchanfrage:',
    'Zoom In': 'Hineinzoomen',
    'Zoom Out': 'Herauszoomen',
    from: 'ab',
    'no rating': 'keine Bewertung',
    'not rated': 'nicht bewertet',
    'our information on copyright infringement': 'our information on copyright infringement',
    referencing: 'bezogen auf',
    remove: 'entfernen',
    'submit a support request': 'submit a support request',
    'tracks: {0}': ['Titel: ', ['0']],
    'updated just now': 'gerade eben aktualisiert',
    'will be reviewed for potential marketplace blocks. If the item is deemed in violation of our offensive materials policy, it will be blocked from sale in the marketplace, but will remain in the Database. If you have a concern about copyright, please see':
        'will be reviewed for potential marketplace blocks. If the item is deemed in violation of our offensive materials policy, it will be blocked from sale in the marketplace, but will remain in the Database. If you have a concern about copyright, please see',
    '{0, plural, one {# copy} other {# copies}}': [
        ['0', 'plural', { one: ['#', ' Exemplar'], other: ['#', ' Exemplare'] }],
    ],
    '{0, plural, one {+# more label...} other {+# more labels...}}': [
        ['0', 'plural', { one: ['+', '#', ' weiteres Label...'], other: ['+', '#', ' weitere Label...'] }],
    ],
    '{0, plural, one {See # reply} other {See # replies}}': [
        ['0', 'plural', { one: ['#', ' Antwort zeigen'], other: ['#', 'Antworten zeigen'] }],
    ],
    '{0, plural, one {rated this release 1 star} other {rated this release # stars}}': [
        [
            '0',
            'plural',
            {
                one: 'hat diese Veröffentlichung bewertet 1 sterne',
                other: [' diese Veröffentlichung mit ', '#', ' sterne'],
            },
        ],
    ],
    '{0}': [['0']],
    '{0} from {1}': [['0'], ' ab ', ['1']],
    '{0} in Inventory': [['0'], ' im Inventar'],
    "{0}'s avatar": ['Avatar von ', ['0']],
    '{artistName} - {title} album cover': [['artistName'], ' - ', ['title'], ' Album-Cover'],
    '{copiesText} for sale': [['copiesText'], ' zu verkaufen'],
    '{count, plural, one {# unread message} other {# unread messages}}': [
        ['count', 'plural', { one: ['#', ' ungelesene Nachricht'], other: ['#', ' ungelegene Nachrichten'] }],
    ],
    '{count} in Collection': [['count'], ' in der Sammlung'],
    '{count} in Inventory': [['count'], ' im Inventar'],
    '{count} in Wantlist': [['count'], ' auf der Suchliste'],
    '{count} in cart': [['count'], ' im Warenkorb'],
    '{count} results are available, use up and down arrow keys to navigate.': [
        ['count'],
        ' Ergebnisse sind verfügbar. Verwenden Sie zur Navigation die Aufwärts- und Abwärtspfeile.',
    ],
    '{days, plural, one {Added # day ago} other {Added # days ago}}': [
        ['days', 'plural', { one: ['Vor ', '#', ' Tag hinzugefügt'], other: ['Vor ', '#', ' Tagen hinzugefügt'] }],
    ],
    '{days, plural, one {Edited one day ago} other {Edited # days ago}}': [
        ['days', 'plural', { one: 'Vor einem Tag bearbeitet', other: ['Vor ', '#', ' Tagen bearbeitet'] }],
    ],
    '{days, plural, one {updated # day ago} other {updated # days ago}}': [
        ['days', 'plural', { one: ['vor ', '#', ' Tag aktualisiert'], other: ['vor ', '#', ' Tagen aktualisiert'] }],
    ],
    '{heading}': [['heading']],
    '{hours, plural, one {Added # hour ago} other {Added # hours ago}}': [
        [
            'hours',
            'plural',
            { one: ['Vor ', '#', ' Stunde hinzugefügt'], other: ['Vor ', '#', ' Stunden hinzugefügt'] },
        ],
    ],
    '{hours, plural, one {Edited one hour ago} other {Edited # hours ago}}': [
        ['hours', 'plural', { one: 'Vor einer Stunde bearbeitet', other: ['Vor ', '#', ' Stunden bearbeitet'] }],
    ],
    '{hours, plural, one {updated # hour ago} other {updated # hours ago}}': [
        [
            'hours',
            'plural',
            { one: ['vor ', '#', ' Stunde aktualisiert'], other: ['vor ', '#', ' Stunden aktualisiert'] },
        ],
    ],
    '{key}': [['key']],
    '{min, plural, one {Rate this release 1 star} other {Rate this release # stars}}': [
        [
            'min',
            'plural',
            {
                one: 'Diese Veröffentlichung mit 1 Stern bewerten',
                other: ['Diese Veröffentlichung mit ', '#', ' Sternen bewerten'],
            },
        ],
    ],
    '{minutes, plural, one {Added # minute ago} other {Added # minutes ago}}': [
        [
            'minutes',
            'plural',
            { one: ['Vor ', '#', ' Minute hinzugefügt'], other: ['Vor ', '#', ' Minuten hinzugefügt'] },
        ],
    ],
    '{minutes, plural, one {Edited one minute ago} other {Edited # minutes ago}}': [
        ['minutes', 'plural', { one: 'Vor einer Minute bearbeitet', other: ['Vor ', '#', ' Minuten bearbeitet'] }],
    ],
    '{minutes, plural, one {updated # minute ago} other {updated # minutes ago}}': [
        [
            'minutes',
            'plural',
            { one: ['vor ', '#', ' Minute aktualisiert'], other: ['vor ', '#', ' Minuten aktualisiert'] },
        ],
    ],
    '{months, plural, one {Added # month ago} other {Added # months ago}}': [
        [
            'months',
            'plural',
            { one: ['Vor ', '#', ' Monat hinzugefügt'], other: ['Vor ', '#', ' Monaten hinzugefügt'] },
        ],
    ],
    '{months, plural, one {Edited one month ago} other {Edited # months ago}}': [
        ['months', 'plural', { one: 'Vor einem Monat bearbeitet', other: ['Vor ', '#', ' Monaten bearbeitet'] }],
    ],
    '{months, plural, one {updated # month ago} other {updated # months ago}}': [
        [
            'months',
            'plural',
            { one: ['vor ', '#', ' Monat aktualisiert'], other: ['vor ', '#', ' Monaten aktualisiert'] },
        ],
    ],
    '{name}': [['name']],
    '{name} Discography | Discogs': [['name'], ' Diskographie | Discogs'],
    '{name} by {username} | Discogs Lists': [['name'], ' von ', ['username'], ' | Discogs-Listen'],
    '{name} image': [['name'], ' Bild'],
    '{name} | Discogs': [['name'], ' | Discogs'],
    '{name}, Primary, {0} of {1}': [['name'], ', Primär, ', ['0'], ' von ', ['1']],
    '{name}, Secondary, {0} of {1}': [['name'], ', Sekundär, ', ['0'], ' von ', ['1']],
    '{num} of {total} results': [['num'], ' von ', ['total'], ' Ergebnissen'],
    '{pendingSubmissionsCount} submissions pending': [['pendingSubmissionsCount'], ' Einträge ausstehend'],
    '{referenceType}s': [['referenceType'], 's'],
    '{title}': [['title']],
    '{totalFiltersApplied} Applied': [['totalFiltersApplied'], ' angewendet'],
    '{total} versions': [['total'], ' Versionen'],
    "{username}'s avatar": [['username'], 's Avatar'],
    '{value}': [['value']],
    '{versionCount, plural, one {# credit} other {# credits}}': [
        ['versionCount', 'plural', { one: ['#', ' Mitwirkender'], other: ['#', ' Mitwirkende'] }],
    ],
    '{versionCount, plural, one {# version} other {# versions}}': [
        ['versionCount', 'plural', { one: ['#', ' Version'], other: ['#', ' Versionen'] }],
    ],
    '{years, plural, one {Added # year ago} other {Added # years ago}}': [
        ['years', 'plural', { one: ['Vor ', '#', ' Jahr hinzugefügt'], other: ['Vor ', '#', ' Jahren hinzugefügt'] }],
    ],
    '{years, plural, one {Edited one year ago} other {Edited # years ago}}': [
        ['years', 'plural', { one: 'Vor einem Jahr bearbeitet', other: ['Vor ', '#', ' Jahren bearbeitet'] }],
    ],
    '{years, plural, one {updated # year ago} other {updated # years ago}}': [
        ['years', 'plural', { one: ['vor ', '#', ' Jahr aktualisiert'], other: ['vor ', '#', ' Jahren aktualisiert'] }],
    ],
}
